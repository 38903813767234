import { Keyboard } from "@capacitor/keyboard";
import { isPlatform } from "@ionic/core";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonInput,
  IonLabel,
  IonRow,
  IonSpinner,
  IonToolbar,
} from "@ionic/react";
import { CbhFeatureFlag, useCbhFlags } from "@src/appV2/FeatureFlags";
import { useToast } from "@src/appV2/lib";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { FC, FormEvent, KeyboardEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Alert from "./Alert";
import { updateAgentData } from "./api";
import { OnBoardingComponentProps } from "./model";
import { StyledIonPage } from "./style";
import { ErrorAlert } from "./types";
import { SUPPORT_LINKS } from "../../../constants/DEFAULT_SUPPORT_LINKS";
import { reloadAgentProfile } from "../../store/session";
import { ONBOARDING_SEGMENT_EVENT_NAMES } from "../constants/ONBOARDING_SEGMENT_EVENT_NAMES";
import { ONBOARDING_STAGES } from "../constants/ONBOARDING_STAGES";
import { logOnboardingError } from "../util/logging";
import { getOigState } from "../util/oig";
import { fireOnboardingSegmentEvent } from "../util/segment";
import "../style.scss";

const AgentSSN: FC<OnBoardingComponentProps> = ({ nextStagePath }) => {
  const { showErrorToast } = useToast();
  const dispatch = useDispatch();
  const [updatingData, setUpdatingData] = useState<boolean>(false);
  const [errorAlert, setErrorAlert] = useState<ErrorAlert>({ hasError: false });

  const [ssn, setSSN] = useState<string | null>(null);

  const worker = useDefinedWorker();

  const maskedSSN = worker.socialSecurityNumber && `xxx-xx-${worker.socialSecurityNumber}`;

  const history = useHistory();
  const ldFlags = useCbhFlags();
  const { [CbhFeatureFlag.SUPPORT_LINKS]: supportLinks } = ldFlags;

  const onNext = async (event?: FormEvent | MouseEvent): Promise<void> => {
    event && event.preventDefault();
    setErrorAlert({ hasError: false });
    try {
      if (ssn !== maskedSSN) {
        if (!ssn?.match("^(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$")) {
          showErrorToast("SSN is invalid");
          return;
        }
        if (isPlatform("capacitor")) {
          Keyboard.hide();
        }
        setUpdatingData(true);

        const oig = getOigState();

        await updateAgentData({
          stage: ONBOARDING_STAGES.SSN,
          ssn: ssn.replace(/\D/g, ""),
          ...(oig?.ssnRequired && {
            validationId: oig.validationId,
            requestFurtherValidation: oig.ssnRequired,
          }),
        });

        fireOnboardingSegmentEvent(ONBOARDING_SEGMENT_EVENT_NAMES.ENTERED_SSN, {
          hcpId: worker.userId,
        });
      }
      dispatch(reloadAgentProfile());
      history.push(`${nextStagePath}`);
    } catch (error) {
      if (!error.response || !error.response.text || error.response.status >= 500) {
        logOnboardingError(ONBOARDING_STAGES.SSN, (error as Error).message, worker.userId);
        setErrorAlert({
          hasError: true,
          reason: error?.message,
        });
        return;
      }
      showErrorToast("Unexpected error occurred while saving ssn");
    } finally {
      setUpdatingData(false);
    }
  };

  const closeErrorModal = () => {
    setErrorAlert({ hasError: false });
  };

  //Function to format input field to SSN
  const formatToSSN = (value: string) => {
    return value
      .replace(/[^0-9x]/g, "")
      .replace(/^(\w{3})/, "$1-")
      .replace(/-(\w{2})/, "-$1-")
      .replace(/(\w)-(\w{4}).*/, "$1-$2");
  };

  const handleOnChange = (event: Event) => {
    const target = event.target as EventTarget;
    target["value"] = target["value"].replace(/[^0-9x-]/g, "");
    setSSN(target["value"]);
  };

  //To format the input only when the key pressed is not backspace and delete
  const handleKeyUp = (event: KeyboardEvent<HTMLIonInputElement>) => {
    let ssNo = event.target["value"] as string;
    if (event.key !== "Backspace" && event.key !== "Delete") {
      ssNo = formatToSSN(ssNo);
    }
    setSSN(ssNo);
  };

  //To prevent inputs which are not number
  const handleKeyPress = (event: KeyboardEvent<HTMLIonInputElement>) => {
    if (isNaN(Number(event.key))) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (maskedSSN) {
      setSSN(maskedSSN);
    }
  }, [maskedSSN]);

  return (
    <StyledIonPage className="onboarding-page">
      <IonHeader no-border className="onboarding-header">
        <IonToolbar className="onboarding-toolbar">
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref="/home/agentAddress" mode="ios" color="dark" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding">
        <Alert
          isOpen={errorAlert.hasError}
          reason={errorAlert?.reason}
          closeAlert={closeErrorModal}
        />

        <div className="signup-content content-layout">
          <div className="form-container">
            <form onSubmit={onNext}>
              <IonRow>
                <IonCol>
                  <div className="form-heading">
                    <h4>What is your SSN?</h4>
                  </div>

                  <div>
                    <IonLabel className="form-label">Social Security Number</IonLabel>
                    <IonInput
                      name="ssn"
                      className="form-input"
                      type="text"
                      onIonChange={handleOnChange}
                      onKeyUp={handleKeyUp}
                      onKeyPress={handleKeyPress}
                      value={ssn}
                      maxlength={11}
                      disabled={updatingData}
                      autofocus
                      required
                    />
                  </div>
                  <p className="ssn-text">
                    We take data privacy seriously. We store an encrypted version of your SSN, and
                    we only provide it to government agencies and background check services to stay
                    compliant with relevant State and Federal laws.
                    <a
                      data-testid="ssn_support_link"
                      href={supportLinks?.WHY_SSN ?? SUPPORT_LINKS.WHY_SSN}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      Click here{" "}
                    </a>
                    to learn more about why we need your SSN, and what we do to keep your personal
                    information secure.
                  </p>
                </IonCol>
              </IonRow>
            </form>
          </div>

          <div className="signupform-footer footer-container">
            <IonButton
              expand="block"
              size="large"
              class="ion-margin-top ion-margin-bottom continue-button"
              disabled={updatingData || ssn?.length !== 11}
              onClick={onNext}
            >
              Continue
              {updatingData && <IonSpinner slot="end" class="ion-margin-start" name="lines" />}
            </IonButton>
          </div>
        </div>
      </IonContent>
    </StyledIonPage>
  );
};

export { AgentSSN };
