import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonToolbar,
} from "@ionic/react";
import { FC, FormEvent } from "react";
import { useHistory } from "react-router-dom";

import { OnBoardingComponentProps } from "./model";
import { OnboardingHeader } from "./OnboardingHeader";
import { OnboardingSteps } from "./OnboardingSteps";
import { StyledIonPage } from "./style";
import { ONBOARDING_SEGMENT_EVENT_NAMES } from "../constants/ONBOARDING_SEGMENT_EVENT_NAMES";
import { ONBOARDING_STEPS } from "../constants/ONBOARDING_STEPS";
import { useIsScheduledAssessmentAvailableForOnboarding } from "../hooks/isScheduledAssessmentAvailableForOnboarding";
import { fireOnboardingSegmentEvent } from "../util/segment";
const AgentSignUpInfo3: FC<OnBoardingComponentProps> = ({ agent, nextStagePath }) => {
  const history = useHistory();

  const onNext = async (event?: FormEvent | MouseEvent) => {
    event?.preventDefault();
    fireOnboardingSegmentEvent(ONBOARDING_SEGMENT_EVENT_NAMES.PROCEEDED_TO_PAY_INFO, {
      hcpId: agent?.userId as string,
    });
    history.push(`${nextStagePath}`);
  };

  const { isScheduledAssessmentAvailableForOnboarding } =
    useIsScheduledAssessmentAvailableForOnboarding({ agent });

  return (
    <StyledIonPage className="onboarding-page">
      <IonHeader no-border className="onboarding-header">
        <IonToolbar className="onboarding-toolbar">
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref="/home/agentQualification" mode="ios" color="dark" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding">
        <div className="signup-content content-layout">
          <div className="form-container">
            <OnboardingHeader
              activeStep={ONBOARDING_STEPS.CONTRACT_AND_PAY_INFORMATION}
              isScheduledAssessmentAvailableForOnboarding={
                isScheduledAssessmentAvailableForOnboarding
              }
            />

            <OnboardingSteps
              activeStep={ONBOARDING_STEPS.CONTRACT_AND_PAY_INFORMATION}
              isScheduledAssessmentAvailableForOnboarding={
                isScheduledAssessmentAvailableForOnboarding
              }
            />
          </div>

          <div className="signupform-footer footer-container">
            <form onSubmit={onNext}>
              <IonButton
                expand="block"
                size="large"
                class="ion-margin-top ion-margin-bottom continue-button"
                onClick={onNext}
              >
                Get set up to get paid
              </IonButton>
            </form>
          </div>
        </div>
      </IonContent>
    </StyledIonPage>
  );
};

export { AgentSignUpInfo3 };
