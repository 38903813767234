import { isDefined } from "@clipboard-health/util-ts";
import {
  IonAlert,
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonSpinner,
  IonToolbar,
} from "@ionic/react";
import { useSession } from "@src/app/store/helperHooks";
import { logEvent } from "@src/appV2/lib/analytics";
import { useIsWorkTypeOnboardingEnabled } from "@src/appV2/redesign/Onboarding/useIsWorkTypeOnboardingEnabled";
import { useSkillsAssessmentFeatureFlags } from "@src/appV2/SkillsAssessment/api/useSkillsAssessmentFeatureFlags";
import { useSkillsAssessments } from "@src/appV2/SkillsAssessment/api/useSkillsAssessments";
import { AssessmentType } from "@src/appV2/SkillsAssessment/types";
import { Agent, SignUpStatus } from "@src/lib/interface";
import { getAgentByPhone, logOutAgentAuth, logout } from "@store/session";
import { FC, FormEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { OnBoardingComponentProps } from "./model";
import { OnboardingHeader } from "./OnboardingHeader";
import { OnboardingSteps } from "./OnboardingSteps";
import { StyledIonPage } from "./style";
import { USER_EVENTS } from "../../../constants";
import { ONBOARDING_SEGMENT_EVENT_NAMES } from "../constants/ONBOARDING_SEGMENT_EVENT_NAMES";
import { ONBOARDING_STAGES } from "../constants/ONBOARDING_STAGES";
import { ONBOARDING_STEPS } from "../constants/ONBOARDING_STEPS";
import { useIsScheduledAssessmentAvailableForOnboarding } from "../hooks/isScheduledAssessmentAvailableForOnboarding";
import { STAGES } from "../model";
import { fireOnboardingSegmentEvent } from "../util/segment";

const AgentSignUpInfo1: FC<OnBoardingComponentProps> = ({ agent, nextStagePath }) => {
  const history = useHistory();
  const [agentSignUpStatus, setAgentSignUpStatus] = useState<SignUpStatus>("unknown");
  const [checkingSignUpStatus, setCheckingSignUpStatus] = useState<boolean>(true);
  const { profile } = useSession();
  const [dbAgent, setDBAgent] = useState<Agent | null>(null);

  const dispatch = useDispatch();

  const onNext = async (event?: FormEvent | MouseEvent) => {
    event?.preventDefault();
    history.push(`${nextStagePath}`);
  };

  /**
   * checks if the agent has an abandoned sign up
   */
  const checkAbandonedSignup = async () => {
    try {
      if (profile?.phone && agent) {
        const phone = profile.phone.substr(-10, 10);
        const agent = await getAgentByPhone(phone);
        setDBAgent(agent);

        const { onboardingFlags: { isSignupCompleted } = {} } = agent;
        let signUpStatus: SignUpStatus;
        if (!agent) {
          signUpStatus = "unstarted";
        } else if (agent && isSignupCompleted) {
          signUpStatus = "complete";
        } else {
          signUpStatus = "inprogress";
        }
        setAgentSignUpStatus(signUpStatus);
      }
    } catch (error) {
      logEvent(USER_EVENTS.ONBOARDING_ERROR, {
        reason: "Unable to check abandoned sign up",
        agent: agent?.userId as string,
        phone: profile?.phone as string,
      });
    } finally {
      setCheckingSignUpStatus(false);
    }
  };

  useEffect(() => {
    checkAbandonedSignup();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * starts over the sign up flow
   */
  const onStartOverSignUp = () => {
    try {
      if (!dbAgent) {
        return;
      }
      fireOnboardingSegmentEvent(ONBOARDING_SEGMENT_EVENT_NAMES.RESTARTED_SIGNUP, {
        hcpId: dbAgent.userId as string,
      });
    } catch (error) {
      logEvent(USER_EVENTS.ONBOARDING_ERROR, {
        reason: "Unable to start over sign up flow",
        message: error?.message,
      });
    }
  };

  const { isSkillsAssessmentOnboardingEnabled, skillsAssessmentConfig } =
    useSkillsAssessmentFeatureFlags();
  const { data: skillAssesssments, isSuccess: isSuccessSkillAssessments } = useSkillsAssessments(
    {
      workerId: agent?.userId ?? "",
      qualificationNames: skillsAssessmentConfig.availableQualificationNames,
    },
    { enabled: isSkillsAssessmentOnboardingEnabled && isDefined(agent?.userId) }
  );

  const { isScheduledAssessmentAvailableForOnboarding } =
    useIsScheduledAssessmentAvailableForOnboarding({ agent });

  const isWorkTypeOnboardingEnabled = useIsWorkTypeOnboardingEnabled();

  /**
   * redirects to the last screen in the signup flow
   */
  const onContinueSignUp = () => {
    try {
      if (!dbAgent) {
        return;
      }

      fireOnboardingSegmentEvent(ONBOARDING_SEGMENT_EVENT_NAMES.CONTINUED_SIGNUP, {
        hcpId: dbAgent.userId as string,
        phone: dbAgent.phone as string,
      });

      let { onboardingFlags: { signupStage } = {} } = dbAgent;
      const hasScheduledAssessmentAvailable =
        isSuccessSkillAssessments &&
        skillAssesssments.data.length > 0 &&
        skillAssesssments.included.some(
          (included) =>
            included.type === "assessment-status" &&
            included.attributes.type === AssessmentType.SCHEDULED
        );
      const hasAlreadyScheduledAssessment =
        isSuccessSkillAssessments &&
        skillAssesssments.included.some(
          (included) =>
            included.type === "assessment-event" && isDefined(included.attributes.scheduledFor)
        );
      const shouldContinueToSkillsAssessments =
        hasScheduledAssessmentAvailable && !hasAlreadyScheduledAssessment;

      if (signupStage === ONBOARDING_STAGES.NAME) {
        signupStage = "agentLastName";
      }
      if (STAGES[signupStage as string]) {
        // The next step after "uploadDocuments" is conditionally "scheduledAssessmentInstructions".
        if (signupStage === "uploadDocuments" && shouldContinueToSkillsAssessments) {
          history.push(STAGES.scheduleAssessment.path);
        } else {
          history.push(STAGES[signupStage as string].nextStagePath);
        }
      }
    } catch (error) {
      logEvent(USER_EVENTS.ONBOARDING_ERROR, {
        reason: "Unable to continue sign up flow",
        message: error?.message,
      });
    }
  };

  /**
   * redirect to login screen
   */
  const onLogIn = () => {
    dispatch(logOutAgentAuth());
    history.push("/welcome/login");
  };

  return (
    <StyledIonPage className="onboarding-page">
      <IonHeader no-border className="onboarding-header">
        <IonToolbar className="onboarding-toolbar">
          {isWorkTypeOnboardingEnabled && (
            <IonButtons slot="start">
              <IonBackButton
                text=""
                defaultHref="/home/interestedWorkType"
                mode="ios"
                color="dark"
              />
            </IonButtons>
          )}
          <IonButtons slot="end">
            <IonButton
              className="top-btns close"
              fill="clear"
              slot="icon-only"
              size="large"
              onClick={() => dispatch(logout())}
              color="dark"
            >
              <IonIcon icon="close" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding">
        <div className="signup-content content-layout">
          <div className="form-container">
            <OnboardingHeader
              activeStep={ONBOARDING_STEPS.PERSONAL_INFORMATION}
              isScheduledAssessmentAvailableForOnboarding={
                isScheduledAssessmentAvailableForOnboarding
              }
            />

            <OnboardingSteps
              activeStep={ONBOARDING_STEPS.PERSONAL_INFORMATION}
              isScheduledAssessmentAvailableForOnboarding={
                isScheduledAssessmentAvailableForOnboarding
              }
            />
          </div>

          <div className="signupform-footer footer-container">
            <form onSubmit={onNext}>
              <IonButton
                expand="block"
                size="large"
                class="ion-margin-top ion-margin-bottom continue-button"
                onClick={onNext}
                disabled={checkingSignUpStatus}
              >
                {checkingSignUpStatus ? "Checking Signup Status" : "Let's go"}
                {checkingSignUpStatus && (
                  <IonSpinner slot="end" class="ion-margin-start" name="lines" />
                )}
              </IonButton>
            </form>
          </div>
        </div>

        <IonAlert
          // If assessments are enabled, only open the alert after assessments finish loading, because IonAlert doesn't update the button callbacks (specifically onContinueSignUp) after the alert has been opened.
          // If the alert is opened while the assessment query is still loading, then clicking "Continue" will never send the worker to the assessments step.
          isOpen={
            agentSignUpStatus === "inprogress" &&
            (!(isSkillsAssessmentOnboardingEnabled && isDefined(agent?.userId)) ||
              isSuccessSkillAssessments)
          }
          header="It looks like you have an abandoned signup"
          message="Start over, or continue where you left off"
          backdropDismiss={false}
          buttons={[
            {
              text: "Start Over",
              handler: onStartOverSignUp,
            },
            {
              text: "Continue",
              handler: onContinueSignUp,
            },
          ]}
        />
        <IonAlert
          isOpen={agentSignUpStatus === "complete"}
          header="Account with this phone already exists"
          message="Login using your data"
          backdropDismiss={false}
          buttons={[
            {
              text: "Log In",
              handler: onLogIn,
            },
          ]}
        />
      </IonContent>
    </StyledIonPage>
  );
};

export { AgentSignUpInfo1 };
